<template>
    <div id="Details" :class="theme || 'theme-red-CB0709'" v-title :data-title="title || '活动中台'">
        <login v-if="show"></login>
        <div class="Details">
            <img class="return" @click="reBack()" src="../../../assets/images/VoteTemp-1/detailReturn.png" alt="">
            <img class="video" :src="data.optionImg" v-if="!data.optionVideo" alt="">
            <div class="video" v-if="data.optionVideo">
                <video id="video"
                       :src="data.optionVideo"
                       :poster="data.optionImg"
                       x5-playsinline
                       preload="auto"
                       loop
                       playsinline
                       webkit-playsinline
                       controls="controls">
                </video>
                <!--                <video  id="video" :src="data.optionVideo"-->
                <!--                        :poster="bannerVideo+'?vframe/png/offset/1'" x5-playsinline preload="auto" loop-->
                <!--                        playsinline webkit-playsinline controls="controls">-->
                <!--                </video>-->
            </div>
            <div class="voteNumbers">
                <div class="voteNumCon">
                    <p class="p1">{{ data.rank ? data.rank : '-'}}</p>
                    <p class="p2">排名</p>
                </div>
                <div class="voteNumCon">
                    <p class="p1">{{ data.voteCount }}</p>
                    <p class="p2">点赞数</p>
                </div>
            </div>
            <iframe v-if="data.externalUrl" :src="data.externalUrl" frameborder="0" style="height: 90vh"></iframe>
            <div class="content">
                <p class="title">{{ data.optionName }}</p>
                <!-- <p class="txtCon">{{data.optionIntroduction}}</p> -->
                <div class="txtCon" v-html="optionIntroduction"></div>
            </div>
            <div class="voteImg"  v-show="!relateLotteryId" @click="vote(data.optionId)">点赞</div>

<!--            <img class="voteImg" v-show="!relateLotteryId" @click="vote(data.optionId)"-->
<!--                 src="../../../assets/images/VoteTemp-1/detailVote.png" alt="">-->
            <div class="bt-button" v-show="relateLotteryId">
                <div @click="toLottery">前往抽奖</div>
                <div @click="vote(data.optionId)">点赞</div>
            </div>
        </div>
    </div>
</template>

<script>
    import login from '../../../components/login'
    import wxShare from '../../../assets/js/wx-share'
    import {getIsApp, isWeixin, loginWx, WXauthorizeWx} from '../../../assets/js/wx-authorize'
    import {Toast} from "vant";
    import {getVoteOptionInfo, operateVote, getVoteOptionDetails} from '../../../assets/api/vote'
    import {handleModel} from "../../../assets/js/untils";
    import qs from "qs";

    export default {
        name: "Details",
        components: {
            login,
        },
        data() {
            return {
                bannerImg: 'https://img.qiluyidian.net/jJ2P5i_1639029527590.jpg',
                bannerVideo: 'https://img.qiluyidian.net/K2Na8t_1639033504103.mp4',
                data: {},
                optionIntroduction: '',
                show: false,
                relateLotteryId: '',
                voteTimeStatus: '',
                wxShareInfo: '',
                title: ''
            }
        },
        created() {
            this.relateLotteryId = this.$route.query.relateLotteryId
            this.voteTimeStatus = this.$route.query.voteTimeStatus
            this.data = this.$route.query
            this.wxShareInfo = this.$route.query
            this.$store.commit('SET_AUTH_Type', this.data.authType)

            console.log(111, this.data)
            this.getVoteOptionInfo()
        },
        mounted() {
            // if (!this.token) {
            //     if (isWeixin()) {
            //         loginWx()
            //     } else {
            //         this.show = false
            //     }
            // }
        },
        methods: {
            toLottery() {
                this.$router.replace({
                    path: '/draw1',
                    query: {
                        lotteryId: this.relateLotteryId
                    }
                })
            },
            async getVoteOptionInfo() {
                let {data, code} = await getVoteOptionDetails(this.data.optionId)
                console.log(data)
                if (code == 200) {
                    // this.data = handleModel(this.data, data)

                    this.data = data
                    this.optionIntroduction = this.data.optionIntroduction.replace(/\n/g, "<br>")
                    if (isWeixin()) {
                        // let {title, weShareSummary, weShareImg} = this.$route.query
                        let {optionImg, optionSerial, optionIntroduction, optionName} = data
                        let str = `快来给${optionSerial}号${optionName}点赞吧`
                        this.title = str
                        let wxlink = window.location.href
                        let [params, query] = wxlink.split('?')
                        query = qs.parse(query)
                        delete query.code
                        delete query.state
                        console.log(qs.stringify(query))
                        // console.log(params + '?' + qs.stringify(query))
                        wxlink = params + '?' + qs.stringify(query)
                        wxShare.loadWxConfig(str, optionIntroduction, wxlink, optionImg)
                    }
                    if (data.authType) {
                        console.log(data)
                        this.$store.commit('SET_AUTH_Type', data.authType)

                    }
                    // if (isWeixin()) {
                    //     let {title, weShareSummary, weShareImg} = res.data
                    //     wxShare.loadWxConfig(title, weShareSummary, window.location.href, weShareImg)
                    // }
                }

            },
            reBack() {
                this.$router.replace({
                    path: '/vote1',
                    query: {
                        voteId: this.data.voteId
                    }
                })
                // window.history.back()
            },
            vote(id) {
                console.log(id)
                if (this.voteTimeStatus == 3) {
                    this.$toast('活动已结束')
                    return false
                }
                if (getIsApp()) {
                    if(this.isFlutter) {
                        window.h5SendAppToken = (data) => {
                            if (data) {
                                this.$store.commit('CHANGE_TOKEN', JSON.parse(data).token)
                                this.operateVote(id)
                            }
                        }
                        window.flutter_inappwebview.callHandler('h5GetApptoken')
                    }
                    this.$bridge.registerHandler('h5SendAppToken', (data, responseCallback) => {
                        console.log(data)
                        if (data) {
                            this.$store.commit('CHANGE_TOKEN', JSON.parse(data).token)
                            this.operateVote(id)
                        }
                    })
                    this.$bridge.callHandler('h5GetApptoken')

                } else if (!this.token) {
                    if (isWeixin()) {
                        loginWx()
                    } else {
                        if (this.authType == 2) {
                            this.$toast('请在微信端打开')
                        } else {
                            this.show = true
                        }
                    }
                } else {
                    this.operateVote(id)
                }

                // console.log(this.data)
                // if (this.voteTimeStatus == 3) {
                //     this.$toast('活动已结束')
                //     return false
                // }
                // console.log('id', id)
                // if (!this.token) {
                //     if (isWeixin()) {
                //         loginWx()
                //     } else {
                //         // console.log(111)
                //         this.show = true
                //     }
                // } else {
                //     //    投票接口
                //     console.log(this.token)
                //     this.operateVote(id)
                // }
            },
            operateVote(id) {
                console.log('vote')
                let params = {
                    "voteId": this.data.voteId,
                    "optionId": id
                };
                operateVote(params).then(res => {
                    console.log(res.data)
                    if (res.code == 200) {
                        Toast('点赞成功')
                        this.getVoteOptionInfo()
                    } else if (res.code == 517) {
                        Toast('活动参与次数已用尽')
                    } else if (res.code == 514) {
                        if (isWeixin()) {
                            WXauthorizeWx()
                        }

                    } else {
                        Toast(res.msg)
                    }
                }).catch(err => {
                })
            }

        },
        computed: {
            theme() {
                return this.$store.getters.getTheme
            },
            token() {
                return this.$store.getters.getToken
            },
            loginDialog() {
                return this.$store.getters.getLoginDialog
            },
            authType() {
                return this.$store.getters.getAuthType
            },
            isFlutter() {
                return this.$store.getters.getFlutter
            }
        },
        watch: {
            loginDialog(val) {
                console.log('open', val)
                if (val) {
                    this.show = true
                }
            }
        }

    }
</script>

<style scoped lang="less">
    @import "../../../assets/css/theme";

    .module-theme(@theme, @tag) {
        .Details {
            max-width: 375px;
            min-height: calc(100vh - 34px);
            margin: auto;
            background-color: #fff;
            padding-bottom: 34px;

            .return {
                width: 27px;
                height: 85px;
                position: fixed;
                right: 0;
                top: 427px;
            }

            .video {
                width: 375px;
                /*height: 414px;*/
                border-radius: 2px;
                display: block;
                object-fit: cover;
            }

            #video {
                width: 375px;
                /*height: 414px;*/
                /*height: auto;*/
                border-radius: 2px;
                display: block;
                object-fit: cover;
            }

            .voteNumbers {
                width: 343px;
                height: 66px;
                border-radius: 4px;
                margin: 24px auto 24px;
                display: flex;
                justify-content: space-between;
                background: #FEF5F5;

                .voteNumCon {
                    width: 172px;
                    height: 42px;
                    margin: 11px 0;
                    border-right: 1px solid #F2BEBE;
                    color: #333;

                    .p1 {
                        font-size: 20px;
                        line-height: 28px;
                        color: @theme;
                    }

                    .p2 {
                        font-size: 13px;
                        line-height: 18px;
                    }
                }

                .voteNumCon:nth-child(2) {
                    border-right: none;
                }
            }

            .content {
                height: auto;
                font-size: 13px;
                color: #666666;
                line-height: 18px;
                text-align: left;
                margin: 0 16px;

                .title {
                    font-size: 16px;
                    font-weight: bold;
                    color: #333333;
                    line-height: 22px;
                    margin-bottom: 15px;
                }

                .txtCon {
                    word-break: break-all;
                    text-align: justify;
                }
            }

            .voteImg {
                margin: 38px auto 34px;
                width: 343px;
                height: 40px;
                display: block;
                /*margin-top: 10px;*/
                color: white;
                background: @theme;
                line-height: 40px;
                font-weight: bold;
            }

            .bt-button {
                width: 345px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 38px 15px 34px;

                > div {
                    width: 160px;
                    height: 40px;
                    text-align: center;
                    line-height: 40px;
                    box-sizing: border-box;
                    border-radius: 5px;

                    &:nth-of-type(1) {
                        border: 1px solid @theme;
                        color: @theme;

                    }

                    &:nth-of-type(2) {
                        background: @theme;
                        color: white;
                    }
                }
            }
        }
    }
</style>
